<template>
  <div
    style="display: flex; flex-direction: column; width: 100%;"
  >
    <div class="navbar-container d-flex content align-items-center w-100">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>

      <b-button
        v-if="!account.isSubscribed"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat-success"
        class="premium-btn"
        to="/billing"
      >
        <feather-icon
          size="16"
          icon="StarIcon"
          class="mr-1"
        />
        <div
          style="display:flex;flex-direction:column;text-align:left"
        >
          <b>Go Premium</b>
          <span
            v-if="account.companyPlan === 'FREE'"
            class="font-small-1">{{ account.trialDaysLeft > 0 ? account.trialDaysLeft : 0 }} days trial left</span>
        </div>
      </b-button>

      <!-- Left Col -->
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name mb-1">
                {{ account.name }}
              </p>
              <span class="user-status">{{ account.email }}</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
              :src="account.pictureURL"
            />
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-2"
            />
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      banner: require('@/assets/images/rebranding/banner.png'),
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
  },
  methods: {
    async logout() {
      await trackEvent({ type: 'dashboard-signed_out' })
      await this.$router.push('/login')
    },
    openPage(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

.premium-btn {
  display:flex;
  align-items:center;
  background-color: map-get($success-color, 'lighten-1');
}

.premium-btn:hover {
  background-color: rgba(40, 199, 111, 0.55);
}

.alert {
  position: absolute;
  padding:15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.banner {
  margin-bottom: 1.3rem;
  transition: 0.3s;
}

.banner:hover{
  filter: drop-shadow(0px 6px 6px #00000014);
}
</style>
