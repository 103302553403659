const getNavigationItems = crm => ([
  {
    header: 'Account',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SlidersIcon',
  },
  {
    title: 'Team',
    route: 'team',
    icon: 'UsersIcon',
  },
  {
    title: 'Analytics',
    route: 'analytics',
    icon: 'BarChart2Icon',
    admin: true,
    enabledForNonAdmins: 'enableNonAdminViewAnalytics',
  },
  {
    title: 'Billing',
    route: 'billing',
    icon: 'CreditCardIcon',
    admin: true,
  },
  {
    header: 'Features',
  },
  (crm !== 'GSHEETS') ? {
    title: `${crm === 'HUBSPOT' ? 'Properties' : 'Fields'} Mapping`,
    route: 'fields-mapping',
    icon: 'RepeatIcon',
    user: true,
  } : null,
  (crm !== 'GSHEETS') ? {
    title: `Default ${crm === 'HUBSPOT' ? 'Properties' : 'Fields'}`,
    route: 'default-fields',
    icon: 'ListIcon',
    user: true,
  } : null,
  {
    title: 'Tools & Apps',
    route: 'tools-apps',
    icon: 'ToolIcon',
  },
  {
    header: 'More',
  },
  {
    title: 'Help Center',
    href: 'https://intercom.help/surfe/en/',
    icon: 'InfoIcon',
  },
  {
    title: 'Roadmap',
    href: 'https://roadmap.surfe.com/',
    icon: 'PackageIcon',
  },
].filter(Boolean))

export default getNavigationItems
